<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <circle cx="12" cy="12" r="9" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.83 9.16992L9.17004 14.8299Z"
      fill="#E5472D"
    />
    <path
      d="M14.83 9.16992L9.17004 14.8299"
      stroke="#E5472D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.83 14.8299L9.17004 9.16992Z"
      fill="#E5472D"
    />
    <path
      d="M14.83 14.8299L9.17004 9.16992"
      stroke="#E5472D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {};
</script>
